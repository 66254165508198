// 导入API接口函数
import { indexSystem } from "@/api";

// 首页模块
export default {
  namespaced: true, // 命名空间
  state: {
    systemInfo: {}
  },

  mutations: {
    SET_SYSTEM_INFO(state, info) {
      state.systemInfo = info
    },
  },

  actions: {
    // 基础配置
    async indexSystem({ commit }) {
      let res = await indexSystem();
      console.log(res, '基础配置');
      commit("SET_SYSTEM_INFO", res);
    },
  },
};
