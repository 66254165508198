<template>
  <div>
    <!-- 网页端顶部 -->
    <div
      class="header dis_jus hidden-xs"
      :class="whether ? 'on' : ''"
      @mouseover="over"
      @mouseout="handleScroll"
    >
      <div class="logo1" @click="$router.push('/home')">
        <img :src="systemInfo.logo1" alt="" />
      </div>

      <div class="rightText dis_aic">
        <div class="text">
          <div
            class="firNav"
            :class="whether ? 'active' : ''"
            @click="$router.push('/home')"
          >
            首页
          </div>

          <div class="sedNav">
            <div class="dis_center">
              <div
                class="sedNav_1"
                @click.stop="
                  $router.push({ path: '/home', hash: '#brand-intro' })
                "
              >
                品牌介绍
              </div>
              <div
                class="sedNav_1"
                @click.stop="
                  $router.push({
                    path: '/home',
                    hash: '#formula-philosophy',
                  })
                "
              >
                配方理念
              </div>
              <div
                class="sedNav_1"
                @click.stop="
                  $router.push({ path: '/home', hash: '#hot-products' })
                "
              >
                热卖产品
              </div>
              <div
                class="sedNav_1"
                @click.stop="
                  $router.push({ path: '/home', hash: '#brand-news' })
                "
              >
                品牌资讯
              </div>
              <div
                class="sedNav_1"
                @click.stop="
                  $router.push({ path: '/home', hash: '#brand-origin' })
                "
              >
                品牌起源
              </div>
            </div>
          </div>
        </div>
        <div class="text">
          <div
            class="firNav"
            :class="whether ? 'active' : ''"
            @click="$router.push('/about')"
          >
            关于建华
          </div>
          <div class="sedNav">
            <div class="dis_center">
              <div
                class="sedNav_1"
                @click.stop="
                  $router.push({ path: '/about', hash: '#brand-origin' })
                "
              >
                品牌起源
              </div>
              <div
                class="sedNav_1"
                @click.stop="$router.push({ path: '/about', hash: '#museum' })"
              >
                芝麻油博物馆
              </div>
              <div
                class="sedNav_1"
                @click.stop="$router.push({ path: '/about', hash: '#product' })"
              >
                产品
              </div>
              <div
                class="sedNav_1"
                @click.stop="
                  $router.push({ path: '/about', hash: '#research' })
                "
              >
                研发实力
              </div>
            </div>
          </div>
        </div>
        <div class="text">
          <div
            class="firNav"
            :class="whether ? 'active' : ''"
            @click="$router.push('/product')"
          >
            古法新养▪产品
          </div>
          <div class="sedNav">
            <div class="dis_center">
              <div
                class="sedNav_1"
                @click.stop="
                  $router.push({ path: '/product', hash: '#differentiating' })
                "
              >
                差异化价值
              </div>
              <div
                class="sedNav_1"
                @click.stop="
                  $router.push({
                    path: '/product',
                    hash: '#product-introduction',
                  })
                "
              >
                产品介绍
              </div>
            </div>
          </div>
        </div>
        <div class="text">
          <div
            class="firNav"
            :class="whether ? 'active' : ''"
            @click="$router.push('/news')"
          >
            新闻资讯
          </div>
          <div class="sedNav">
            <div class="dis_center"></div>
          </div>
        </div>
        <div class="text">
          <div
            class="firNav"
            :class="whether ? 'active' : ''"
            @click="$router.push('/contact')"
          >
            联系我们
          </div>
          <div class="sedNav">
            <div class="dis_center"></div>
          </div>
        </div>
      </div>

      <div class="right_icon">
        <a :href="systemInfo.tmall_link" target="_blank">
          <img
            class="shopImg"
            :src="$route.path == '/detail' ? shop2 : shop"
            alt=""
          />
        </a>
      </div>
    </div>

    <div class="phone visible-xs">
      <div class="phoneCon">
        <div class="logo1" @click="$router.push('/home')">
          <img :src="systemInfo.logo1" alt="" />
        </div>
        <div class="phoneMenu" @click="menuShow()">
          <span></span><span></span><span></span>
        </div>
        <div class="phoneList" :class="{ menuShow: menu }">
          <div
            class=""
            @click="
              menu = false;
              $router.push('/home');
            "
          >
            首页
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push('/about');
            "
          >
            关于建华
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push('/product');
            "
          >
            古法新养▪产品
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push('/news');
            "
          >
            新闻资讯
          </div>
          <div
            class=""
            @click="
              menu = false;
              $router.push('/contact');
            "
          >
            联系我们
          </div>
        </div>
      </div>
    </div>

    <div class="backTop" :class="whether ? 'block' : ''" @click="goBack">
      <img src="@/assets/images/backTop.png" alt="" />
    </div>
  </div>
</template>

<script>
window.onload = function () {
  window.scrollTo(0, 0);
};
import { mapState } from "vuex";

export default {
  components: {},
  props: {},
  computed: {
    ...mapState("home", ["systemInfo"]),
  },
  data() {
    return {
      menu: false,
      whether: false,
      shop: require("@/assets/images/shop.png"),
      shop2: require("@/assets/images/shop2.png"),
    };
  },

  mounted() {
    // console.log(this.$router);
  },

  created() {
    this.screenHeight = window.innerHeight;
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  methods: {
    over() {
      this.whether = true;
      this.shop = this.shop2;
    },
    menuShow() {
      if (this.menu) {
        this.menu = false;
      } else {
        this.menu = true;
      }
    },

    handleScroll() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollTop > 300) {
        this.whether = true;
        this.shop = this.shop2;
      } else {
        this.whether = false;
        this.shop = require("@/assets/images/shop.png");
      }
    },

    goBack() {
      window.scrollTo(0, 0);
    },

    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.logo1 img {
  width: 171px;
  height: 45px;
  cursor: pointer;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 110px;
  padding: 0 100px;

  .rightText {
    height: 110px;

    .text {
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      line-height: 110px;
      padding: 0 80px;
      cursor: pointer;
    }

    .firNav {
      position: relative;
    }

    .firNav::after {
      content: "";
      display: block;
      width: 80%;
      height: 2px;
      background-color: red;
      position: absolute;
      left: 10%;
      bottom: 30px;
      transform: scaleX(0);
      transition: all 0.3s ease-in-out;
    }

    .firNav:hover::after,
    .text.on.firNav::after {
      transform: scaleX(1);
    }

    .active {
      color: #333333;
    }

    .text:hover .firNav {
      color: red;
    }

    .text:hover .sedNav {
      display: block;
    }

    .sedNav {
      position: absolute;
      top: 110px;
      left: 0;
      width: 100%;
      background-color: #fff;
      // border-top: 1px solid rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      display: none;

      .sedNav_1 {
        font-size: 18px;
        color: #333333;
        line-height: 80px;
        padding: 0 50px;
      }
    }

    .sedNav .sedNav_1:hover {
      color: red;
    }
  }

  .header.on .headerNav > li > a::after {
    background-color: #000;
  }

  .right_icon img {
    width: 32px;
    height: 22px;
    cursor: pointer;
  }
}

.on {
  background: #fff;
  box-shadow: 0px 0px 5px 0 #333333;
}

.backTop {
  position: fixed;
  right: 4%;
  bottom: 10%;
  transition: opacity 3s ease-in-out;
  display: none;
  cursor: pointer;
  z-index: 999;

  img {
    width: 53px;
  }
}

.block {
  display: block;
}

.backTop:hover {
  transform: scale(1.05);
}

.visible-xs {
  display: none !important;
}

@media (max-width: 768px) {
  .visible-xs {
    display: block !important;
  }

  .hidden-xs {
    display: none !important;
  }
  .phone {
    width: 100%;
    height: 52px;
    background: #ffffff;
    padding: 0 20px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  .phoneCon {
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .phoneCon .logo1 img {
    width: 110px;
    height: auto;
  }
  .phoneMenu {
    width: 22px;
  }
  .phoneMenu span {
    display: block;
    width: 22px;
    height: 2px;
    border-radius: 1px;
    background-color: #000;
  }
  .phoneMenu span:nth-child(n + 2) {
    margin-top: 5px;
  }
  .phoneList {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding: 0 20px;
    background-color: #fff;
    border-top: 1px solid #e5e5e5;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
    position: absolute;
    top: 52px;
    left: 0;
    z-index: 2;
    transition: all 0.3s ease-in-out;
  }
  .phoneList.menuShow {
    height: 220px;
  }
  .phoneList div {
    width: 100%;
    font-size: 14px;
    color: #333;
    line-height: 40px;
    text-align: center;
    border-bottom: 1px dotted #e5e5e5;
  }
  .backTop img {
    width: 40px;
    height: 40px;
  }
}
</style>
