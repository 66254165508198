<template>
  <div class="FixIcon">
    <div class="fix">
      <div class="kefu">
        <!-- <img width="26" src="@/assets/images/ic_kefu.png" alt="" /> -->
        <div>客服</div>
        <div class="line"></div>
        <div class="telNumber">{{ configList.service_mobile }}</div>
      </div>
      <div class="qr_code">
        <!-- <img width="44" src="@/assets/images/QR_code.png" alt="" /> -->
        <div>商城小程序</div>
        <div class="pic">
          <img width="88" :src="configList.service_qr_code" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "FixIcon",

  computed: {
    ...mapState("header", ["configList"]),
  },
};
</script>

<style lang="scss" scoped>
.FixIcon {
  .fix {
    position: fixed;
    // top: 700px;
    // right: 210px;
    top: 50%;
    margin-top: 75px;
    right: 50%;
    margin-right: -750px;
    width: 82px;
    background: #ffffff;
    z-index: 999;

    .kefu {
      position: relative;
      width: 82px;
      height: 78px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      padding-top: 13px;
      cursor: pointer;

      &:hover .telNumber {
        display: block;
      }

      .line {
        width: 52px;
        height: 1px;
        background: rgba(0, 0, 0, 0.1);
        margin: 8px auto 0;
      }

      .telNumber {
        position: absolute;
        top: 10px;
        left: -120px;
        width: 120px;
        height: 40px;
        line-height: 40px;
        // border: 1px solid red;
        color: #00a4a0;
        display: none;
      }
    }

    .qr_code {
      position: relative;
      width: 82px;
      height: 82px;
      font-size: 12px;
      font-weight: 400;
      color: #333333;
      text-align: center;
      padding-top: 10px;
      cursor: pointer;

      &:hover .pic {
        display: block;
      }

      .pic {
        position: absolute;
        top: -15px;
        left: -100px;
        width: 100px;
        height: 100px;
        text-align: center;
        display: none;
      }
    }
  }
}
</style>
