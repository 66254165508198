<template>
  <div class="footer">
    <div class="content_left">
      <div class="logo2"><img :src="systemInfo.logo2" alt="" /></div>
      <div class="tel_address">
        <div>电话：{{ systemInfo.tel }}</div>
        <div>地址：{{ systemInfo.address }}</div>
      </div>
      <div class="three_icon">
        <div class="dis_img">
          <img class="qq_image" :src="systemInfo.wx_image" alt="" />
          <img class="wechat_icon" src="@/assets/images/wechat.png" alt="" />
        </div>
        <div class="dis_img">
          <img class="qq_image" :src="systemInfo.tmall_image" alt="" />
          <img class="weibo_icon" src="@/assets/images/tianmao.png" alt="" />
        </div>
        <div class="dis_img">
          <img class="qq_image" :src="systemInfo.douyin_image" alt="" />
          <img class="qq_icon" src="@/assets/images/douyin.png" alt="" />
        </div>
      </div>
    </div>

    <div class="content_center">
      <div class="nav_text">
        <div @click="$router.push('/home')">首页</div>
        <div @click="$router.push({ path: '/home', hash: '#brand-intro' })">
          品牌介绍
        </div>
        <div
          @click="$router.push({ path: '/home', hash: '#formula-philosophy' })"
        >
          配方理念
        </div>
        <div @click="$router.push({ path: '/home', hash: '#hot-products' })">
          热卖产品
        </div>
        <div @click="$router.push({ path: '/home', hash: '#brand-news' })">
          品牌资讯
        </div>
        <div @click="$router.push({ path: '/home', hash: '#brand-origin' })">
          品牌起源
        </div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/about')">关于建华</div>
        <div @click="$router.push({ path: '/about', hash: '#brand-origin' })">
          品牌起源
        </div>
        <div @click="$router.push({ path: '/about', hash: '#museum' })">
          芝麻油博物馆
        </div>
        <div @click="$router.push({ path: '/about', hash: '#product' })">
          产品
        </div>
        <div @click="$router.push({ path: '/about', hash: '#research' })">
          研发实力
        </div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/product')">古法新养·产品</div>
        <div
          @click="$router.push({ path: '/product', hash: '#differentiating' })"
        >
          差异化价值
        </div>
        <div
          @click="
            $router.push({ path: '/product', hash: '#product-introduction' })
          "
        >
          产品介绍
        </div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/news')">新闻资讯</div>
      </div>
      <div class="nav_text">
        <div @click="$router.push('/contact')">联系我们</div>
      </div>
    </div>

    <div class="bottom_line">
      <div>Copyright © 2023,www.scjhxy.com,All rights reserved</div>
      <div>
        <a href="https://beian.miit.gov.cn/" target="_blank">
          {{ systemInfo.beian }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
window.onload = function () {
  window.scrollTo(0, 0);
};

import { mapState } from "vuex";

export default {
  components: {},
  props: {},
  data() {
    return {};
  },

  computed: {
    ...mapState("home", ["systemInfo"]),
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  position: relative;
  width: 100%;
  height: 503px;
  background: url("@/assets/images/homeBg2.png");

  .content_left {
    padding-top: 3%;
    padding-left: 10%;

    .logo2 img {
      width: 190px;
    }

    .tel_address {
      width: 303px;
      font-size: calc(100vw * 14 / 1920);
      font-family: Source Han Serif SC;
      font-weight: 300;
      color: #999999;
      line-height: calc(100vw * 28 / 1920);
      margin: 3% 0;
    }

    .three_icon {
      display: flex;
      align-items: center;

      .dis_img {
        position: relative;
        margin-right: 30px;
        cursor: pointer;

        .qq_image {
          position: absolute;
          bottom: 50px;
          left: -35px;
          width: 110px;
          visibility: hidden;
          opacity: 0;
          filter: alpha(opacity=0);
          transition: all 0.3s ease-in-out;
        }

        .wechat_icon,
        .weibo_icon,
        .qq_icon {
          width: 42px;
          height: 42px;
        }
      }

      .dis_img:hover .qq_image {
        visibility: visible;
        opacity: 1;
        filter: alpha(opacity=100);
      }

      // .dis_img:hover {
      //   transform: scale(1.2);
      // }
    }
  }

  .content_center {
    position: absolute;
    top: 20%;
    right: 20%;
    display: flex;

    .nav_text {
      font-size: calc(100vw * 14 / 1920);
      font-family: Source Han Serif SC;
      font-weight: 300;
      color: #999999;
      line-height: 42px;
      margin-left: 60px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
    }

    .nav_text div:first-child {
      font-size: calc(100vw * 16 / 1920);
      font-weight: 500;
      color: #333333;
    }

    .nav_text div:hover {
      font-weight: 500;
      color: red;
      transform: translateX(10px);
    }
  }

  .bottom_line {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: calc(100vw * 12 / 1920);
    font-family: Source Han Serif SC;
    font-weight: 300;
    color: #999999;
    border-top: 1px solid rgba(42, 38, 37, 0.1);
    text-align: center;
    line-height: 50px;
    div:nth-child(n + 2) {
      margin-left: 20px;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    height: auto;
  }

  .content_left,
  .content_center {
    display: none !important;
  }

  .footer .bottom_line {
    font-size: 10px;
    height: auto;
    padding: 10px;
    position: relative;
  }

  .bottom_line {
    flex-direction: column;
    line-height: 20px !important;
  }
}
</style>
