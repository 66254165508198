// 封装请求库
import Axios from "axios";
// 按需引入element的消息弹窗
import { Message } from "element-ui";

// 通过create创建axios实例
const request = Axios.create({
  // 配置公共请求地址
  baseURL: "http://jhxy.admin.hzgdwl.com/api/",
  // 请求超时时间
  timeout: 5000,
});

// 定义请求拦截器
request.interceptors.request.use(
  (config) => {
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

// 定义响应拦截器
request.interceptors.response.use(
  // 响应成功
  async (res) => {
    // 取出响应结果
    const data = res.data;
    // 判断响应码
    if (data.code !== 200) {
      Message(data.msg || "响应错误");
      return Promise.reject(new Error(data.msg));
    }

    return data.data || data;
  },
  // 响应失败
  (err) => {
    console.log(err, '定义响应拦截器');
    // Message(err)
    new Promise(new Error(err));
  }
);

// 导入创建的axios实例
export default ({ method, url, data }) => {
  return request({
    method,
    url,
    // 如果方法为get则使用 params传值 否则使用data传值
    [method.toLowerCase() === "get" ? "params" : "data"]: data,
  });
};
