<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },

  mounted: function () {
    // 调用actions获取导航分类
    this.$store.dispatch("home/indexSystem");
  },

  methods: {},
};
</script>

<style lang="scss">

@media (max-width: 768px) {
  .content{
    padding-top: 52px;
  }
}
</style>
