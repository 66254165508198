import Vue from "vue";
import VueRouter from "vue-router";
// 导入布局组件
import Layout from "@/layout";

// 自己编写跳转路由方法
// 保存VueRouter的push方法
const vPush = VueRouter.prototype.push;
// 重写vue路由push方法 将push方法默认携带函数
VueRouter.prototype.push = function (location, cb1, cb2) {
  if (cb1 || cb2) return vPush.call(this, location, cb1, cb2);
  return vPush.call(this, location, () => { });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    // 路由重定向到主页
    redirect: "/home",
    children: [
      // 主页
      { path: "/home", component: () => import("@/views/home") },
      // 关于我们
      {
        path: "/about",
        component: () => import("@/views/about"),
      },
      // 古法新养▪产品
      {
        path: "/product",
        component: () => import("@/views/product"),
      },
      // 新闻资讯
      {
        path: "/news",
        component: () => import("@/views/news"),
      },
      // 联系我们
      {
        path: "/contact",
        component: () => import("@/views/contact"),
        name: "contact",
      },
      // 新闻详情
      {
        path: "/detail",
        component: () => import("@/views/detail"),
        name: "detail",
      },
    ],
  }
];

const router = new VueRouter({
  routes,
  Layout,
  // 滚动行为：设置页面滚动条位置
  // behavior: 'smooth' 设置滚动动画
  // scrollBehavior: (to, from, savedPosition) => {
  //   // 如果是回退行为 则不回到顶部
  //   if (savedPosition) return savedPosition;
  //   return { x: 0, y: 0, behavior: "smooth" };
  // },

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition;
    // console.log(to, savedPosition);
    if (to.hash) {
      return {
        selector: to.hash,
      };
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  next();
});

export default router;
