// 所有请求接口的集合
import request from "@/utils/request";

// 基础配置
export const indexSystem = () => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "system/config",
  });
};

// 首页banner1
export const banner = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "banner/getList",
    data
  });
};

// 配方理念 
export const recipe = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "index/getRecipeList",
    data
  });
};

// 热卖产品 
export const hotProduct = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "index/getHotproductList",
    data
  });
};

// 新闻列表 
export const news = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "news/getPageList",
    data
  });
};

// 新闻详情
export const detail = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "news/getDetail",
    data
  });
};

// 关于建华基础配置
export const aboutConfig = () => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "about/config",
  });
};

// 博物馆
export const museum = (data) => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "about/getMuseumList",
    data
  });
};

//  人群健康产品 
export const healthProducts = () => {
  // 因为request请求的结果为promise  直接return promise可供调用者使用
  return request({
    method: "GET",
    url: "about/getPopulationHealthProductsList",
  });
};