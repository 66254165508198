import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 初始化CSS
import "normalize.css";

// 导入重置CSS文件
import "./style/css/global.css";
import "./style/reset.scss";

// 导入全局组件
import "@/components";

// 导入element-ui组件
import { Cascader, DatePicker, InputNumber, Message, pagination, Radio, Select, Switch } from 'element-ui';

Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Cascader)
Vue.use(Select)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(pagination)
Vue.prototype.$message = Message
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
